<template>
  <div class="home">
    <div class="main-container">
      <section class="hero-container">
        <img class="mobile" src="/img/mobile_hero.png" alt="mobile_hero" />
        <img class="desktop" src="/img/desktop_hero.png" alt="desktop_hero" />
      </section>
      <section class="main-content">
        <div class="logo-container">
          <img src="/img/skyy_logo.svg" alt="skyy_logo" />
        </div>
        <p class="pre-first">
          Thank you for participating in the SKYY Vodka Trivia GAME
        </p>
        <p class="first">
          <span>You did great!</span> Enjoy this refreshing offer:
        </p>
        <img class="image" src="/img/images/skyy_off.svg" alt="" srcset="" />
        <p class="second">delivery on Drizly</p>
        <p class="third">
          Through 12/31/23 by using the code
          <span class="code">ENJOYSKYY</span> during checkout*
        </p>
        <a
          class="btn"
          target="_blank"
          @click="track"
          href="https://drizly.com/liquor-brands/skyy/b2474?utm_medium=partner&utm_source=crm&utm_campaign=12+skyy+2023+crm++collection"
          >ORDER HERE</a
        >
      </section>
    </div>

    <section class="terms-content">
      <div class="terms-container">
        <section>
          <img
            class="seal"
            src="/img/skyy_seal.svg"
            alt="skyy_seal"
            srcset=""
          />
        </section>
        <section>
          <p class="terms">
            SKYY® Vodka distilled from grain. 40% alc./vol. (80 Proof). ©2023
            Campari America, New York, NY. Please enjoy responsibly.
            <br /><br />
            *Courtesy of Drizly. New Drizly users only. Must be 21+. Cannot be
            combined with any other offer. Per applicable law, value may be
            applied towards order total or shipping or delivery fees. Void where
            prohibited. Expires on 12/31/2023 at 11:59 EDT.
          </p>
        </section>
      </div>
    </section>
  </div>
</template>

<script>
// import axios from 'axios';

import API from '../../utils/api';

export default {
  name: 'HomeView',
  components: {},
  data() {
    return {};
  },
  methods: {
    async testAPI() {
      console.log('Testing API...');
      const { data } = await API.get('/test');
      console.log(data);
    },
    async log(message) {
      console.log('Testing API DB...');
      const payload = {
        interaction: message,
      };
      const { data } = await API.post('/log', payload);
      console.log(data);
    },
    track() {
      this.$gtag.event('btn_here_click', {
        event_category: 'click',
        event_label: 'btn_here_click',
        value: 0,
      });
    },
  },
  async beforeMount() {
    // this.testAPI();
    // this.log('Loaded application...');
  },
};
</script>

<style lang="scss">
$fontBold: 'Skyymono Bold';
$fontLight: 'Skyymono Regular';
$fontMedium: 'Skyymono Medium', sans-serif;
$fontBoldMaison: 'MaisonNeue Bold';
$fontLightMaison: 'MaisonNeue Regular';

.home {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  flex-wrap: wrap;
  position: relative;

  @media screen and (min-width: 600px) {
    flex-direction: row;
  }

  .main-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    background: linear-gradient(
      90deg,
      #0a1349 -13.18%,
      #0042b9 48.12%,
      #00bdd3 114.45%
    );

    @media screen and (min-width: 600px) {
      flex-direction: row;
      min-height: 100vh;
      section {
        width: 50%;
      }
    }

    .hero-container {
      .desktop {
        display: none;
        position: relative;
        width: 100%;
        height: 100%;
        object-fit: cover;

        @media screen and (min-width: 600px) {
          & {
            display: flex;
          }
        }
      }
      .mobile {
        display: flex;
        width: 100%;
        @media screen and (min-width: 600px) {
          & {
            display: none;
          }
        }
      }
    }

    .main-content {
      display: flex;
      flex-direction: column;
      flex: 1;
      padding: 15px;
      align-items: center;
      text-align: center;

      @media screen and (min-width: 600px) {
        padding: 50px 70px 240px 70px;
      }

      @media screen and (min-width: 601px) and (max-width: 834px) {
        padding: 50px 70px 290px 70px;
      }

      .logo-container {
        margin: 25px 0;
      }

      p.pre-first {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 140.5%;
        margin-bottom: 0;
        /* or 25px */

        text-align: center;
        text-transform: uppercase;
        font-family: $fontBold;

        @media screen and (min-width: 600px) {
          font-size: 24px;
          line-height: 140.5%;
          margin: 3px 0 32px 0;
        }
      }

      p.first {
        font-style: normal;
        font-size: 16px;
        line-height: 140.5%;
        /* or 25px */

        text-align: center;
        font-family: $fontLightMaison;

        span{
          font-family: $fontBoldMaison;
          font-weight: 700;
        }

        @media screen and (min-width: 600px) {
          font-size: 20px;
          line-height: 140.5%;
          margin: 3px 0 32px 0;
        }
      }

      p.second {
        font-style: normal;
        font-size: 23.0486px;
        line-height: 108.5%;
        margin: 10px 0 0 0;
        text-align: center;
        text-transform: uppercase;
        font-family: $fontBold;

        @media screen and (min-width: 600px) {
          font-size: 30.8973px;
          line-height: 108.5%;
        }
      }

      img.image {
        width: 350px;

        @media screen and (min-width: 600px) {
          width: 470px;
        }
      }

      p.third {
        font-weight: 500;
        font-size: 16px;
        line-height: 140%;
        width: 80%;
        font-family: $fontLightMaison;

        @media screen and (min-width: 600px) {
          font-size: 20px;
          line-height: 140%;
          margin: 32px 0 20px 0;
          padding: 0;
          width: 60%;
        }

        span.code {
          background-color: rgba(255, 255, 255, 0.35);
          font-family: $fontLightMaison;
          letter-spacing: 3px;
          padding: 0 5px;

          @media screen and (min-width: 600px) {
            font-size: 20px;
            line-height: 140%;
          }
        }
      }

      a.btn {
        width: 278px;
        height: 46px;
        // background-color: #ffffff;
        background-color: #0a1349;
        // box-shadow: 0px 4.27006px 8.54011px rgba(183, 53, 2, 0.88);
        box-shadow: 0px 4.82301px 9.64603px #13206c;
        border: none;
        border-radius: 37.1584px;
        margin: 10px 0 33px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        // color: #fa4700;
        color: white;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        font-family: $fontBold;
        text-decoration: none;

        @media screen and (min-width: 600px) {
          width: 278px;
          height: 50px;
          margin: 21px 0 54px 0;
          transition: all 125ms;
          font-size: 20px;
          line-height: 27px;
        }

        &:hover {
          transition: all 125ms;
          cursor: pointer;
          opacity: 0.8;
        }

        &:active {
          transition: all 125ms;
          cursor: pointer;
          opacity: 0.8;
        }
      }
    }
  }

  .terms-content {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 15px;
    align-items: center;
    text-align: center;
    background: linear-gradient(
      270deg,
      rgba(10, 19, 73, 0.9) -6.16%,
      rgba(0, 66, 185, 0.9) 65.63%,
      rgba(0, 189, 211, 0.9) 141.53%
    );

    @media screen and (min-width: 600px) {
      background: linear-gradient(
      270deg,
      rgba(10, 19, 73, 0.8) -13.18%,
      rgba(0, 66, 185, 0.8) 48.12%,
      rgba(0, 189, 211, 0.8) 114.45%
    );
      position: absolute;
      bottom: 0;
      padding: 0;
    }

    div.terms-container {
      @media screen and (min-width: 600px) {
        display: flex;
        align-items: center;
        section {
          width: 50%;
          display: flex;
        }
      }

      img.seal {
        display: none;
        @media screen and (min-width: 600px) {
          justify-self: flex-start;
          padding-left: 6px;
          padding-bottom: 8px;
          display: flex;
        }
      }

      p.terms {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
        font-family: $fontLightMaison;
        padding: 0 25px;

        @media screen and (min-width: 600px) {
          font-size: 16px;
          line-height: 140%;
        }
      }
    }
  }
}
</style>
